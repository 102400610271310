function loadGoogleAnalytics() {
    const googleAnalyticsId = window.GoogleAnalyticsId;
    if (googleAnalyticsId) {
        let script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=' + googleAnalyticsId;
        document.head.appendChild(script);
        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            window.gtag = gtag;
            gtag('js', new Date());
            gtag('config', googleAnalyticsId, {
                'send_page_view': false
            });
            gtag('consent', 'default', {
                'analytics_storage': 'denied',
            });
        };
    } else {
        console.error("Google Analytics ID is not set.");
    }
}

let events = ['mouseover', 'keydown', 'scroll', 'touchstart'];
events.forEach(function(event) {
    window.addEventListener(event, function handler(e) {
        loadGoogleAnalytics();
        events.forEach(function(event) {
            window.removeEventListener(event, handler);
        });
    }, { once: true, passive: true });
});
