import "../css/index.css";
import "../assets/images/logo-full-color.png";
import "../assets/images/favicon-48x48.png";
import "./hubspot";
import "./googleAnalytics";
import "./leadFormPopup";
import "@fontsource/material-symbols-sharp";
import "./serviceContactForm";

function loadFlowbite() {
    import("flowbite")
        .then(() => {})
        .catch((error) => {
            console.error("Error loading Flowbite:", error);
        });
}


document.addEventListener("DOMContentLoaded", () => {
  loadFlowbite();
});