function loadHubSpotScript(callback) {
    const hubspotScriptUrl = "//js.hsforms.net/forms/embed/v2.js";
    let script = document.querySelector(`script[src="${hubspotScriptUrl}"]`);

    if (!script) {
        script = document.createElement('script');
        script.src = hubspotScriptUrl;
        document.body.appendChild(script);
        script.onload = () => {
            if (window.hbspt && window.hbspt.forms) {
                callback();
            } else {
                console.error('HubSpot script loaded but window.hbspt.forms is undefined.');
            }
        };
    } else if (window.hbspt && window.hbspt.forms) {
        callback(); // Execute callback if script is already loaded and initialized
    } else {
        // Handle case where script is present but not yet initialized
        script.addEventListener('load', callback);
    }
}

  
  function renderHubSpotForm(formConfig) {
    if (window.hbspt) {
        hbspt.forms.create(formConfig);
    }
  }

const hubspotSmallContactFormConfig = {
    region: "na1",
    portalId: "40034998",
    formId: "69686439-353b-4b77-9f4d-0bd18820e919",
    target: "#hubspotSmallContactFormContainer",
    localStorageKey: "hubspot-small-contact-form-html"
}

const popupForm = {
    region: "na1",
    portalId: "40034998",
    formId: "dc820f93-6f53-4d46-b305-c837dd571be8",
    target: "#hubspot-popup-form-container",
    localStorageKey: "hubspot-popup-contact-form-html"
};

const loadAndCacheHubSpotForm = (formConfig) => {
    const { localStorageKey } = formConfig;
    delete formConfig.localStorageKey;
    const cachedFormHtml = localStorage.getItem(localStorageKey);
    const formContainer = document.querySelector(formConfig.target);
    if (cachedFormHtml) {
        formContainer.innerHTML = cachedFormHtml;
    }

    loadHubSpotScript(() => {
        renderHubSpotForm(formConfig);
        setTimeout(() => {
            const newFormHtml = formContainer.innerHTML;
            if (newFormHtml !== cachedFormHtml) {
                localStorage.setItem(localStorageKey, newFormHtml);
            }
        }, 1000);
    });
}

function initializeForms() {
    if (document.querySelector(hubspotSmallContactFormConfig.target)) {
        loadAndCacheHubSpotForm(hubspotSmallContactFormConfig);
    }

    if (document.querySelector(popupForm.target)) {
        loadAndCacheHubSpotForm(popupForm);
    }
}

// Call this function on page load or when it's sure the DOM is fully loaded

if ('requestIdleCallback' in window) {
    requestIdleCallback(initializeForms, { timeout: 200 });
} else {
    // Fallback for browsers that do not support requestIdleCallback
    document.addEventListener('DOMContentLoaded', initializeForms);
}