import { Modal } from 'flowbite';

// Set the modal menu element
const $targetEl = document.getElementById('popup-lead-form');

// Options with default values
const options = {
    closable: true,
    onHide: () => {
        localStorage.setItem('popupLeadFormLastClosed', new Date().getTime());
    },
};

// Instance options object
const instanceOptions = {
  id: 'popup-lead-form',
  override: true
};

const modal = new Modal($targetEl, options, instanceOptions);

const popupCloseButton = $targetEl.querySelector("#popup-lead-form-close");
if (popupCloseButton) {
    popupCloseButton.addEventListener("click", () => {
        modal.hide();
    });
}

// Show the modal based on the condition
const popupLeadFormLastClosed = localStorage.getItem('popupLeadFormLastClosed');
const daysSinceLastClose = (new Date().getTime() - popupLeadFormLastClosed) / (1000 * 3600 * 24);

const url = window.location.href.split("/")
const secondSegment = url[3];

if (!popupLeadFormLastClosed || daysSinceLastClose > 5) {
    setTimeout(() => {
        if (secondSegment === "thank-you") {
            localStorage.setItem('popupLeadFormLastClosed', new Date().getTime());
        } else {
            modal.show();
        }
    }, 7000);
}
