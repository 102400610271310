const form = document.getElementById('service-contact-form');

form.addEventListener('submit', async function(event) {
    event.preventDefault();
    
    const formData = new FormData(event.target);
    const data = {};
    formData.forEach((value, key) => {
        data[key] = value;
    });

    // Send the form data to the server-side script to process
    const response = await fetch('/actions/hubspot/main/submit-form', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    const result = await response.json();
    if (result.success) {
        const thankYouSlug = form.getAttribute('redirect');
        window.location.href = `/thank-you/${thankYouSlug}`;
    } else {
        alert('There was an error submitting the form.');
    }
});